<template lang="pug">
analytics-company(
  tab="AnalyseCustomers"
  placeholder="Поиск заказчика (начните вводить наименование, ИНН или адрес поставщика и выберите из списка)"
  :tabs="customersTabs"
  :average-cards="averageCards"
  :filters="advancedFilters"
)
</template>

<script lang="ts">
import { defineComponent, provide } from "vue";
import AnalyticsCompany from "@/components/pages/analytics/CompanyPage.vue";

export default defineComponent({
  name: "AnalyticsCustomer",
  components: {
    AnalyticsCompany,
  },
  setup() {

    const averageCards = [
      [
        { label: "Общее количество заказов", valueNum: "total" },
        { label: "на сумму", valueNum: "lotSummary", compact: true, currency: true },
      ],
      [
        { label: "Актуальных заказов", valueNum: "actual" },
        { label: "на сумму", valueNum: "actualSummary", compact: true, currency: true },
      ],
      [
        { label: "Завершенных заказов", valueNum: "completed" },
        { label: "на сумму", valueNum: "completedSummary", compact: true, currency: true },
      ],
      [{ label: "Количество поставщиков", valueNum: "suppliersCount" }],
    ];

    const customersTabs = [
      { title: "Актуальные", mode: "actual" },
      { title: "Завершенные", mode: "completed" },
      { title: "Участники", mode: "participants" },
      { title: "Поставщики", mode: "suppliers" },
    ]

    const advancedFilters = [
      'LawSiteKpp',
      'DatePublicAndType',
      'PriceAndPaymentTerms',
      'CategoryOkpdAndSuppliers',
    ];

    provide("type", "AnalyseCustomers");

    return {
      averageCards,
      customersTabs,
      advancedFilters,
    };
  },
});
</script>

<style scoped lang="scss">
</style>
